import '../styles/globals.css';

import App, { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { formatTitle } from '../util/format-title';
import Head from 'next/head';
import { FontFamily, Store, StoreStatus } from '../types/api/entities/store';
import { DisabledStore } from '../components/disabled-store';
import Router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useStore } from '../store/use-store';
import { AppContext } from '../types/app-context';
import { CustomAppProps } from '../types/app';
import { appWithTranslation } from 'next-i18next';
import { AxiosWithApi, ApiService } from '../services/api';

// store: Store<StoreState, StoreAction>;

function renderGaScript(id: string) {
  return `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${id}');
  `;
}

function MyApp({ Component, pageProps, apiBaseUrl, cookie }: AppProps & CustomAppProps) {
  const store = useStore(pageProps.initialReduxState, { apiBaseUrl, cookie });

  const router = useRouter();

  const [ephemeralStore, setEphemeralStore] = useState<Store>();
  const [gaTags, setGaTags] = useState<React.ReactNode>('');
  const [secondaryFontFamily, setSecondaryFontFamily] = useState<FontFamily>({
    url: 'https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700',
    name: 'Libre Baskerville',
  });

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (store) {
        // sometimes this is undefined :/
        const state = store.getState();
        const storeEntity = state.entities.store;
        if (storeEntity.gaId) {
          // setTimeout is used here so we can get an accurate window.document.title
          // remove setTimeout if you test with GA on client side page loads and GA recognizes page titles properly
          setTimeout(() => {
            (window as any).gtag('config', storeEntity.gaId, {
              page_path: url,
              page_title: window.document.title,
            });
          }, 0);
        }
      }
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return function cleanup() {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [store]);

  useEffect(() => {
    console.log('apiBaseUrl L68', apiBaseUrl);
    const Api = new ApiService(AxiosWithApi({ apiBaseUrl, cookie }));

    Api.getStore()
      .then((store) => {
        console.log("Store retreived L73:", store);
        setEphemeralStore(store);

        if (ephemeralStore?.gaId) {
          setGaTags(
            <>
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${ephemeralStore.gaId}`}
              ></script>
              <script
                dangerouslySetInnerHTML={{
                  __html: renderGaScript(ephemeralStore.gaId),
                }}
              />
            </>,
          );
        }

        if (store?.theme?.headingFontFamily) {
          setSecondaryFontFamily(store?.theme?.headingFontFamily);
        }
      })
      .catch((e) => {
        console.error('Could not fetch store on _app load. Error:', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (router.asPath === '/app/status') {
    return <Component />;
  } else {
    return (
      <>
        <Head>
          <title>{ephemeralStore ? formatTitle(ephemeralStore) : ''}</title>
          <link
            key="font-family-primary"
            href="https://fonts.googleapis.com/css?family=Roboto"
            rel="stylesheet"
          />
          <link key="font-family-secondary" href={secondaryFontFamily.url} rel="stylesheet" />

          {/* Inject the Google Analytics snippet into the <head> of the document  */}
          {gaTags}
        </Head>
        <Provider store={store}>
          {
            // If ephemeralStore is not set yet, err on the side of not turning off store.
            ephemeralStore === undefined ||
              ephemeralStore?.status === StoreStatus.Trialing ||
              ephemeralStore?.status === StoreStatus.TrialWillEnd ||
              ephemeralStore?.status === StoreStatus.Active ||
              ephemeralStore?.status === StoreStatus.Delinquent ? (
              <Component {...pageProps} />
            ) : (
              <DisabledStore />
            )
          }
        </Provider>
        <style jsx>{`
          :global(:root) {
            --font-family-primary: 'Roboto';
            --font-family-secondary: '${secondaryFontFamily.name}';
          }
        `}</style>
      </>
    );
  }
}

MyApp.getInitialProps = async (appContext: { ctx: AppContext }): Promise<CustomAppProps> => {
  let cookie: string;
  let apiBaseUrl: string;

  if (appContext.ctx.req) {
    cookie = appContext.ctx.req.headers.cookie || '';
    const host = appContext.ctx.req.headers['codomain'];
    if (host) {
      apiBaseUrl = `https://${host}/api`;
    } else {
      apiBaseUrl = '/api';
    }
  } else {
    cookie = '';
    if (window.location.origin) {
      apiBaseUrl = `${window.location.origin}/api`;
    } else {
      // Sometimes window.location.origin is undefined for some reason
      // If that's the case, we only
      apiBaseUrl = `/api`;
    }
  }

  const appProps = await App.getInitialProps(appContext as any);

  return {
    ...appProps,
    cookie,
    apiBaseUrl,
  };
};

export default appWithTranslation(MyApp as any);
