import React from 'react';
import { withTranslation } from 'next-i18next';
import { WithTranslation as WithTranslationType } from 'next-i18next';

const linkWithUtm = (
  url: string,
  {
    source,
    content,
    campaign,
    term,
    medium,
  }: {
    source?: string;
    campaign?: string;
    content?: string;
    term?: string;
    medium?: string;
  },
) => {
  const urlObj = new URL(url);

  const queryParams = urlObj.searchParams;

  if (source) {
    queryParams.set('utm_source', source);
  }
  if (campaign) {
    queryParams.set('utm_campaign', campaign);
  }
  if (content) {
    queryParams.set('utm_content', content);
  }
  if (term) {
    queryParams.set('utm_term', term);
  }
  if (medium) {
    queryParams.set('utm_medium', medium);
  }

  urlObj.search = queryParams.toString();
  return urlObj.toString();
};

const HOME_LINK = 'https://www.commerceowl.com/';
const SIGNUP_LINK = 'https://app.commerceowl.com/app/signup';

export class _DisabledStore extends React.PureComponent<WithTranslationType> {
  public render() {
    return (
      <div className="bg-gray-100 font-sans w-full min-h-screen m-0 flex flex-col items-center">
        <div className="bg-white shadow w-full">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between py-4">
              <div>
                <a
                  href={linkWithUtm(HOME_LINK, {
                    source: 'sell-with-recipes',
                    content: 'CommerceOwl logo',
                  })}
                >
                  <img className="w-30 h-10" src="/images/logo.svg" />
                </a>
              </div>
              <div className="hidden sm:flex sm:items-center space-x-2">
                <span>Need a recipe site for your business?</span>
                <a
                  href={linkWithUtm(SIGNUP_LINK, {
                    source: 'sell-with-recipes',
                    content: 'CTA',
                    medium: 'desktop',
                  })}
                  className="text-gray-800 text-sm font-semibold border px-4 py-2 rounded-lg hover:text-amber-600 hover:border-amber-600"
                >
                  Sign up for Sell with Recipes
                </a>
              </div>
            </div>

            <div className="block sm:hidden bg-white py-2">
              <div className="flex flex-col">
                <div className="flex justify-between items-center border-t-2 pt-2">
                  <span>Need a recipe site for your business?</span>
                  <a
                    href={linkWithUtm(SIGNUP_LINK, {
                      source: 'sell-with-recipes',
                      content: 'CTA',
                      medium: 'mobile',
                    })}
                    className="text-gray-800 text-sm font-semibold border px-4 py-1 rounded-lg hover:text-amber-600 hover:border-amber-600"
                  >
                    Sign up for Sell with Recipes
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow flex flex-col items-center justify-center">
          <div className="w-400 h-200 p-10 md:p-40 rounded-md bg-white shadow-sm flex flex-col space-y-8 items-center">
            <a
              href={linkWithUtm(HOME_LINK, {
                source: 'sell-with-recipes',
                content: 'Sell with Recipes logo',
              })}
            >
              <img className="w-500" src="/images/sell-with-recipes-logo.png" />
            </a>
            <span className="text-xl">Sorry, this recipe site is currently unavailable.</span>
          </div>
          <span className="mt-2">
            Is this your recipe site? Contact{' '}
            <a href="mailto:support@commerceowl.com" className="text-amber-600">
              support@commerceowl.com
            </a>{' '}
            to re-activate it.
          </span>
        </div>
      </div>
    );
  }
}

export const DisabledStore = withTranslation()(_DisabledStore);
